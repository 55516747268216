module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"36fb18bc-3525-49a2-bed9-7bd1fcd372a4","server":"https://insights.aeroteameindhoven.nl","ignoreLocalhost":true,"ignoreOwnVisits":true,"detailed":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Aero Team Eindhoven","short_name":"Aero Team","start_url":"/","theme_color":"#021e3a","background_color":"#051320","display":"standalone","icon":"/home/runner/work/website/website/src/images/site-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1e3a7f077c2f895be1fdea0d419cf598"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
